.approveUserFromList-modal {
  .form-control {
    background-color: #e9ecef !important;
    opacity: 1 !important;
  }
}

.handleInput {
  border: 0px solid gray !important;
}

.save-message {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #4caf50;
  color: white;
  padding: 16px 32px;
  border-radius: 4px;
  text-align: center;
  z-index: 10000000 !important;
}
