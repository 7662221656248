.handleBtn {
  background-color: #ffc107;
  color: #000;
}
.color {
  color: #ffff !important;
}
.color:hover {
  background-color: #ffc107 !important;
}
.handleFontsize {
  font-size: 0.1rem;
}

/* StyledTable.css */
.styled-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.border-cell {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.handleShowData {
  position: absolute;
  z-index: 15;
  background: #ffffff;
  color: #000;
  /* max-height: 0; */

  transition: max-height 1.5s ease;
}
handleWidth .handleWidth {
  width: 0% !important;
}
.handleShowData.active {
  max-height: 800px; /* Adjust the maximum height based on your content */
}

.radio-label {
  /* margin-right: 5px; */
  color: green !important;
}
.setHeight {
  height: 1.2rem !important;
}
