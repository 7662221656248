header.sticky .main-menu > ul > li span > a {
    color: #444;
}

header.sticky .main-menu > ul > li span:hover > a {
    opacity: 1;
    color: #f0151f;
}

.main-menu ul ul li:hover > a {
    background-color: #f9f9f9;
    color: #f0151f;
    padding-left: 18px;
}

.main-menu ul ul li span a:hover {
    background-color: #f9f9f9;
    color: #f0151f;
}

header.sticky .main-menu > ul > li span > a,
header.header_in .main-menu > ul > li span > a {
    color: #444;
}

header.sticky .main-menu > ul > li span:hover > a,
header.header_in .main-menu > ul > li span:hover > a {
    opacity: 1;
    color: #f0151f;
}

.main_title_2 span em {
    background-color: #f0151f;
}

.main_title_3 span {
    background-color: #f0151f;
}

a {
    color: #f0151f;
}

a:hover,
a:focus {
    color: #111;
    text-decoration: none;
    outline: none;
}

a.btn_1,
.btn_1 {
    background: #f0151f;
}

a.btn_1.outline,
.btn_1.outline {
    border: 2px solid #f0151f;
    color: #f0151f;
}

a.btn_1.full-width.purchase,
.btn_1.full-width.purchase {
    background-color: #f0151f;
}

a.btn_1.full-width.purchase:hover,
.btn_1.full-width.purchase:hover {
    background-color: #0054a6;
    color: #fff !important;
}

a.btn_map:hover {
    color: #f0151f;
}

a.btn_map_in,
.btn_map_in {
    background: #f0151f;
}

a.btn_add,
.btn_add {
    background: #f0151f;
}

a.btn_filt:hover,
a.btn_filt_map:hover {
    color: #f0151f;
}

a.btn_2,
.btn_2 {
    background: #f0151f;
}

a.btn_2:hover,
.btn_2:hover {
    background: #ffc107;
    color: #444;
}

.hero_in a.btn_photos:hover {
    color: #f0151f;
}

#newsletter input[type="submit"] {
    background-color: #f0151f;
}

.follow_us ul li a i {
    color: #555;
    opacity: 0.6;
}

.follow_us ul li a i:hover {
    opacity: 1;
    color: #f0151f;
}

footer ul li a:hover {
    color: #f0151f;
    opacity: 1;
}

footer ul.contacts li i {
    color: #f0151f;
}

footer ul.links li a:hover:after {
    opacity: 1;
    color: #f0151f;
}

.hero_single .wrapper {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.8);
}

/* .hero_single.version_4 {
  background: #f0151f url(/images/pattern_1.svg) center bottom repeat-x;
} */

.hero_single.version_4 .wrapper input[type="submit"] {
    color: #222;
    background-color: #ffc107;
}

.hero_single.version_4 .wrapper input[type="submit"]:hover {
    background-color: #0054a6;
}

.hero_single.version_5 {
    background: #f0151f url("../images//4858794.jpg") center center repeat fixed;
    /* background: #f0151f url(/images/4858794.jpg); */
    background-position: top right;
    background-repeat: no-repeat;
    background-size: cover;
}

.hero_single.version_5 .wrapper {
    background: rgba(0, 0, 0, 0.6);
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0), transparent);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), transparent);
}

.hero_single.version_5 .wrapper input[type="submit"] {
    color: #222;
    background-color: #ffc107;
}

.hero_single.version_5 .wrapper input[type="submit"]:hover {
    background-color: #0054a6;
    color: #fff;
}

a.address:hover {
    color: #f0151f;
}

a.grid_item .info small {
    background-color: #f0151f;
}

.call_section {
    background-color: #f0151f;
    position: relative;
    color: #fff;
}

.call_section .wrapper {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.5);
}

/* .call_section.pattern {
  background: #f0151f url(../img/pattern.svg) center center repeat fixed;
} */

.call_section.pattern .wrapper {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.6);
}

.call_section.image_bg {
    /* background: #f0151f url(../img/bg_call_section.jpg) center center no-repeat
    fixed; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.call_section.image_bg .wrapper {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.8);
}

#custom-search-input input[type="submit"] {
    background-color: #f0151f;
}

.custom-search-input-2 input[type="submit"] {
    color: #222;
    background-color: #ffc107;
}

.custom-search-input-2 input[type="submit"]:hover {
    background-color: #0054a6;
    color: #fff;
}

/* .custom-search-input-2.inner input[type="submit"] {
  background: #ffc107 url(../img/search.svg) no-repeat center center;
} */

.custom-search-input-2.inner input[type="submit"]:hover {
    background-color: #0054a6;
}

/* @media (max-width: 575px) {
  .custom-search-input-2.inner input[type="submit"] {
    background: #f0151f url(../img/search.svg) no-repeat center center;
  }
} */

.search_mob_wp input[type="submit"] {
    background-color: #ffc107;
    color: #222;
}

.search_mob_wp input[type="submit"]:hover {
    background-color: #0054a6;
    color: #fff;
}

.distance span {
    color: #f0151f;
}

.rangeslider__fill {
    background: #f0151f !important;
}

.rangeslider__handle {
    border: 2px solid #f0151f !important;
}

.container_check input:checked ~ .checkmark {
    background-color: #f0151f;
}

.container_radio .checkmark:after {
    background: #f0151f;
}

#search_mobile a.side_panel {
    color: #999;
}

#search_mobile a.side_panel:hover {
    color: #f0151f;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
    background: #f0151f !important;
}

a.box_news:hover h4 {
    color: #f0151f;
}

#results {
    background: #f0151f;
}

#results_map_view {
    color: #fff;
    background: #f0151f;
}

.secondary_nav {
    background: #f0151f;
}

.sub_header_in {
    background-color: #f0151f;
}

.step .nav-tabs .nav-link.active,
.step .nav-tabs .nav-item.show .nav-link {
    border-bottom: 2px solid #f0151f;
    color: #f0151f;
}

.payments ul li a.info {
    color: #444;
}

.payments ul li a.info:hover {
    color: #f0151f;
}

.box_list a.wish_bt.liked:after {
    color: #f0151f;
}

.box_list a.wish_bt:hover.liked:after {
    color: #f0151f;
}

.layout_view a:hover {
    color: #f0151f;
}

.switch-field label:hover {
    color: #f0151f;
}

a.btn_infobox_detail:before:hover {
    color: #f0151f;
}

.btn_infobox_get_directions,
a.btn_infobox_phone {
    color: #f0151f;
}

.contact_info ul li h4 {
    color: #f0151f;
}

.form_title h3 strong {
    background-color: #f0151f;
}

.grid ul li figcaption a:hover,
.grid ul li figcaption a:focus {
    color: #f0151f;
}

ul#cat_nav li a:hover,
ul#cat_nav li a#active,
ul#cat_nav li a.active {
    color: #f0151f;
}

.list_articles ul li a:hover {
    color: #f0151f;
}

#toTop:hover {
    background-color: #f0151f;
}

.accordion_2 .card-header h5 a i.indicator {
    color: #f0151f;
}

#cookie-bar .cb-policy {
    background: #f0151f;
}

.applyBtn.btn.btn-sm.btn-primary {
    background-color: #f0151f;
}

.box_contacts i {
    font-size: 46px;
    font-size: 2.875rem;
    color: #f0151f;
}

.box_contacts a {
    color: #555;
}

.box_contacts a:hover {
    color: #f0151f;
    text-decoration: underline;
}

.animated .block-horizzontal {
    background: #f0151f;
}

.animated .block-vertical {
    background: #f0151f;
}

#carousel .item .title h4 {
    background-color: #f0151f;
    background-color: #f0151f;
}

/* #error_page {
  background: #f0151f url(../img/pattern_1.svg) center bottom repeat-x;
} */

@media (max-width: 767px) {
    #error_page {
        background-size: auto 300px;
    }
}

#error_page .wrapper {
    background: rgba(0, 0, 0, 0.6);
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.6), transparent);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), transparent);
}

.search_bar_error input[type="submit"] {
    background-color: #ffc107;
}

.search_bar_error input[type="submit"]:hover {
    background-color: #0054a6;
    color: #fff;
}

.cluster-visible {
    background-color: #f0151f !important;
}

.cluster-visible:before {
    border: 7px solid #f0151f !important;
    box-shadow: inset 0 0 0 4px #f0151f !important;
}

.marker-cluster div {
    background-color: #f0151f;
}

.marker-cluster-small div,
.marker-cluster-medium div,
.marker-cluster-large div {
    background-color: #f0151f;
}

.marker-cluster div:before {
    border: 7px solid #f0151f;
    box-shadow: inset 0 0 0 4px #f0151f;
}

article.blog .post_info h2 a:hover {
    color: #f0151f;
}

.comments-list h3 a:hover {
    color: #f0151f;
}

.widget ul.cats a:hover {
    color: #f0151f;
}

.tags a:hover {
    background-color: #f0151f;
}

.postmeta ul li a:hover {
    color: #f0151f;
}

.search_blog .form-group input[type="submit"] {
    background-color: #f0151f;
    color: #fff;
}

.pagination a.active {
    background: #f0151f;
    color: white;
}

.pricing-switcher .fieldset {
    background-color: #f0151f;
}

.pricing-switcher .switch {
    background-color: #c50d15;
}

@media only screen and (min-width: 769px) {
    .pricing-wrapper > li::before {
        background-color: #fff;
    }
}
