.pagination-buttons {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
}

.current-page-number {
	margin: 0 10px;
}
