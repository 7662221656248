// // Brand Colors
// $primary: #dc3545;

// $btn-primary-bg: $primary;
// $btn-primary-color: #ffffff;
// $btn-primary-border-color: $primary;

// $secondary: #6c757d;
// $success: #28a745;
// $info: #17a2b8;
// $warning: #ffc107;
// $danger: #dc3545;
// $light: #f8f9fa;
// $dark: #343a40;

// $blue: #007bff;
// $indigo: #6610f2;
// $purple: #6f42c1;
// $pink: #e83e8c;
// $red: #dc3545;
// $orange: #fd7e14;
// $yellow: #ffc107;
// $green: #28a745;
// $teal: #20c997;
// $cyan: #17a2b8;
// $white: #fff;
// $gray-100: #f8f9fa;
// $gray-200: #e9ecef;
// $gray-300: #dee2e6;
// $gray-400: #ced4da;
// $gray-500: #adb5bd;
// $gray-600: #6c757d;
// $gray-700: #495057;
// $gray-800: #343a40;
// $gray-900: #212529;
// $black: #000;

// $grays: (
//   100: $gray-100,
//   200: $gray-200,
//   300: $gray-300,
//   400: $gray-400,
//   500: $gray-500,
//   600: $gray-600,
//   700: $gray-700,
//   800: $gray-800,
//   900: $gray-900
// );

// $theme-colors: (
//   "primary": $primary,
//   "secondary": $secondary,
//   "success": $success,
//   "info": $info,
//   "warning": $warning,
//   "danger": $danger,
//   "light": $light,
//   "dark": $dark
// );

// $colors: (
//   "blue": $blue,
//   "indigo": $indigo,
//   "purple": $purple,
//   "pink": $pink,
//   "red": $red,
//   "orange": $orange,
//   "yellow": $yellow,
//   "green": $green,
//   "teal": $teal,
//   "cyan": $cyan,
//   "white": $white,
//   "gray": $gray-600,
//   "gray-dark": $gray-800
// );

// $theme-color-interval: 8%;
$baseFontSize: 16;

@function rem($pixels, $context: $baseFontSize) {
  @if (unitless($pixels)) {
    $pixels: calc($pixels * 1px);
  }

  @if (unitless($context)) {
    $context: calc($context * 1px);
  }

  @return calc($pixels / $context * 1rem);
  // @return $pixels;

 
  $btn-primary-border-color: $primary;
}


// Brand Colors
$blue: #007bff;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #f0151f;
$orange: #fd7e14;
$yellow: #FFDF7C;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;
$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;
$grays: (
  100: $gray-100,
  200: $gray-200,
  300: $gray-300,
  400: $gray-400,
  500: $gray-500,
  600: $gray-600,
  700: $gray-700,
  800: $gray-800,
  900: $gray-900
);
$theme-colors: (
  primary: $red,
  secondary: $yellow,
  success: $green,
  info: $cyan,
  warning: $yellow,
  danger: $red,
  light: $gray-100,
  dark: $gray-800
);
$colors: (
  blue: $blue,
  indigo: $indigo,
  purple: $purple,
  pink: $pink,
  red: $red,
  orange: $orange,
  yellow: $yellow,
  green: $green,
  teal: $teal,
  cyan: $cyan,
  white: $white,
  gray: $gray-600,
  gray-dark: $gray-800
);
$theme-color-interval: 8%;

$grid-gutter-width: 2.5rem;

:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #FFDF7C;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #f0151f;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #FFDF7C;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg,
      rgba(255, 255, 255, 0.15),
      rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #f0151f;
  ;
  --bs-link-hover-color: #fff;
  --bs-link-hover-color: #fff;
  --bs-btn-color: #fff;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
  --bs-gutter-x: rem(40);
  --bs-dropdown-link-active-bg: $red;
}