/* YOUR CUSTOM CSS */
.dash-nav {
  z-index: 100;
  background-color: #fff;
  padding: 13px 23px;
  border-bottom: 1px solid rgba(255, 255, 255, 0);
  box-shadow: rgba(0, 0, 0, 0.12) 0 10px 60px;
}
.modal-open {
  .dash-nav {
    z-index: 4;
  }
}
.dash-nav.navbar-dark .navbar-nav .nav-link {
  color: #f0151f;
  font-family: 'Bell MT';
  /* font-size: 16px; */
  font-size: 15px;
  font-weight: bold;
  padding: 0;
  display: flex;
}
.dash-nav.navbar-dark .navbar-nav .nav-link span.nav-link-text {
  flex: 1;
}

.dash-foot {
  border-top: 0;
  /* background-color: hsl(215deg 23.53% 20%); */
  background-color: #22303c;
}

.dash-foot-wrapper {
  font-family: 'Bell MT';
  color: #fff;
  padding: 0 70px;
}

.dash-foot-wrapper a h2 {
  color: #fff !important;
  font-size: 1.125rem;
}

.dash-foot-wrapper a h2 {
  color: #fff !important;
}

.quan-toggle {
  font-size: 33px;
  color: #f0151f;
  border: 0;
  display: inline-flex;
}

.quan-toggle:after {
  content: none;
}

.dropdown .quan-menu {
  /* font-family: 'Bell MT';
    transform: translate(0px, 53px) !important;
    border-color: #f3f3f3;
    box-shadow: 0 0.25rem 0.625rem rgba(20, 20, 20, .1);
    padding: 14px 10px;
    top: 0;
    border-radius: 0;
    width: 280px; */
}

.quan-menu a {
  font-size: 15px;
  color: #727b82;
  font-weight: 500;
}

.dropdown .quan-menu .dropdown-item:active {
  background-color: #f0151f;
}

.breadcrumb-item,
.breadcrumb-item a {
  color: #f0151f;
  font-size: 20px;
  @media (max-width: 768px) {
    font-size: 16px;
  }
  &.active {
    color: #f0151f;
    /* color: #ffffff; */
  }
}

.box_general .form-group label {
  font-weight: bold;
}

.mobile-user {
  font-size: 21px;
  padding: 6px;
  border-radius: 50%;
  color: red;
  border: 1px solid red;
}

.dash-nav.navbar-dark .navbar-toggler {
  color: #f0151f;
  border-color: transparent;
  padding: 0;
}

.dash-nav.navbar-dark .navbar-toggler-icon {
  background-image: url('../images/grid-outline.png');
}

.dash-mobile-logo img {
  object-fit: cover;
  height: 30px;
}
.user-dash-tabl {
  /* max-width: 600px;
  margin: 0 auto; */
  .table thead tr th {
    font-weight: bold;
    font-size: 17px;
  }
}

.q-badge {
  padding: 0.3rem 0.45rem;
  font-weight: bold;
  border-radius: 0.25rem;
  /* background-color: rgba(38,191,148,.1);
    color: rgb(38,191,148); */
  background-color: rgba(230, 83, 60, 0.1);
  color: rgb(255 0 28);
}
.dash-img-bg {
  background: url(../images/4858794.jpg);
  /* background-position: center; */
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}
.style-2::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  background-color: #f5f5f5;
}

.style-2::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.style-2::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0151f;
}
.breadcrumb {
  /* background-color: #25313e; */
  background-color: transparent;
  border-radius: 0;
  /* border-bottom: 2px solid #f0151fc2; */
  border-bottom: 2px solid #787878;
    /* object-fit: cover; */
margin-top: 32px;
}

@media only screen and (max-width: 400px) {
  .dash-nav .quan-toggle i.ti-menu {
    font-size: 22px;
  }

  .mobile-user {
    font-size: 16px;
  }

  .dash-mobile-logo img {
    /* object-fit: cover; */
    height: 19px;
  }
}

@media only screen and (max-width: 992px) {
  .dash-nav.navbar .navbar-sidenav .nav-link {
    padding-bottom: 15px;
  }

  #mainNav.navbar-dark
    .navbar-collapse
    .navbar-sidenav
    > .nav-item
    > .nav-link {
    color: #333;
  }

  #mainNav .navbar-collapse {
    margin-top: 20px;
  }

  #mainNav.navbar-dark
    .navbar-collapse
    .navbar-sidenav
    > .nav-item
    > .nav-link:hover {
    color: #f0151f;
  }
}
@media only screen and (max-width: 350px) {
  .logo_normal {
    height: 28px;
  }
  .logo_sticky {
    height: 28px;
  }
  .dropdown .dropdown-menu.res-menu {
    width: 225px;
  }
}

/* grid.svg */

.modal {
  z-index: 99999999;
}
.user-dash-tabl table tbody tr th {
  /* padding: 1.75rem; */
  /* padding: 1.05rem; */
  vertical-align: middle;
}
.user-dash-tabl table tbody tr td {
  vertical-align: middle;
  /* width: 80%; */
}
.sent-btn {
  position: relative;
}
.sent-btn .form-control {
  width: 100%;
}
.sent-btn input {
  padding-right: 0;
}
.sent-btn a {
  position: absolute;
  right: 0;
  color: #fff;
  font-size: 0.8125rem;
  font-weight: 600;
  top: 0;
  border: 0;
  padding: 0 12px;
  height: calc(1.5em + 0.75rem + 2px);
  line-height: 38px;
  cursor: pointer;
  border-radius: 0.25rem;
  /* -webkit-border-radius: 0 3px 3px 0;
    -moz-border-radius: 0 3px 3px 0;
    -ms-border-radius: 0 3px 3px 0;
    border-radius: 0 3px 3px 0; */
  /* border-radius: 10px; */
  background-color: red;
  transition: 0.3s ease-in-out;
  outline: 0;
}
.sent-btn .form-control:focus ~ a {
  border-radius: 10px;
}

@media (max-width: 576px) {
  .dash-foot-wrapper {
    padding: 9px 70px;
  }
}
