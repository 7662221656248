.page-not-found{
    min-height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0px;
    @media (max-width: 960px) {
        padding: 70px 10px 0px 10px;
    }
    .image404{
        height: 60vh;
    }
}