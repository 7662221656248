.custom-text-wrap {
  height: 150px; /* Set initial height */
  min-height: 40px; /* Minimum height to fit a single line */
  max-height: 300px; /* Maximum height before scrolling */
  overflow-y: auto; /* Enable vertical scrolling if content exceeds max-height */
  white-space: pre-wrap; /* Preserve line breaks */
}
.approveUserFromList-modal {
  .form-control {
    background-color: #e9ecef !important;
    opacity: 1 !important;
  }
}

.handleInput {
  border: 0px solid gray !important;
}

.save-message {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #4caf50;
  color: white;
  padding: 16px 32px;
  border-radius: 4px;
  text-align: center;
  z-index: 10000000 !important;
}
