input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s;
    -webkit-text-fill-color: #000 !important;
}

// NOTE: the order of these styles DO matter
$dark-red: #f0151f;

// Will edit everything selected including everything between a range of dates
.CalendarDay__selected_span {
    background: darken($color: $dark-red, $amount: 0.4); //background
    color: white; //text
    border: 1px solid darken($color: $dark-red, $amount: 0.4); //default styles include a border
}

// Will edit selected date or the endpoints of a range of dates
.CalendarDay__selected {
    background: $dark-red;
    color: white;
}

// Will edit when hovered over. _span style also has this property
.CalendarDay__selected:hover {
    background: orange;
    color: white;
}

// Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
    background: $dark-red;
    color: #fff;
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-right-color: $dark-red;
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
    background: darken($color: $dark-red, $amount: 0.4);
    border: 1px double darken($color: $dark-red, $amount: 0.4);
    color: #fff;
}

.DateInput_input {
    font-weight: 200;
    font-size: 16px;
    line-height: 1.4;
}

.DateInput_input__focused {
    outline: 0;
    background: #fff;
    border: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 2px solid $dark-red;
}

.user-dash-tabl {
    .buy-btn {
        padding: 4px;
        line-height: 1;
        margin: -8px;
    }

    table {
        width: 100%;

        thead {
            tr {
                th {
                    // width: 25%;
                    // &:first-child{

                    //   width: 50%;

                    // }
                }
            }
        }

        tbody {
            tr {
                th {
                    // width: 33% !important;
                }

                td {}
            }
        }
    }
}

.sticky-footer {}

.common-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 56px;
    border-top: none;
    line-height: 55px;
    z-index: 2;

    @media (max-width: 576px) {
        height: 73px;
    }
}

.table-fixed-container {
    position: relative;
    z-index: 0;
}

.table-fixed-wrapper {
    width: 100%;
    border-radius: inherit;
    overflow-y: scroll;
    min-height: 0px;
    height: 50vh;

    @media (max-width: 991px) {
        height: 580px;
        overflow-y: hidden;
        overflow-x: scroll;
    }
}

.table-fixed {
    width: 100%;
    background-color: #f3f3f3;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-width: 100%;
    color: rgba(0, 0, 0, 0.87);
    background-color: rgb(255, 255, 255);
    z-index: 0;

    @media (max-width: 991px) {
        // padding-left: 125px;
        position: relative;
        min-width: 10000px;
    }

    thead {
        display: flex;
        width: 100%;
        position: sticky;
        top: 0px;
        z-index: 1;
        color: rgba(0, 0, 0, 0.87);
        font-size: 12px;
        font-weight: 500;

        @media (max-width: 991px) {
            // position: absolute;
            // // width: 150px;
            // left: 0;
            // top: 0;
            width: 150px;

            left: 0;
            top: 0;
            /* You need to specify a top, bottom, left, or right value for sticky to work */
            left: 0;
            /* Left position */
            right: 0;
            /* Right position */
        }

        tr {
            display: flex;
            align-items: stretch;
            width: 100%;
            background-color: #e9ecef;
            min-height: 52px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);

            @media (max-width: 991px) {
                width: 110px;
                flex-wrap: wrap;
                border-bottom-width: 1px;
            }

            th {
                flex: 1 0 0px;
                max-width: 100%;
                min-width: 100px;
                position: relative;
                display: flex;
                align-items: flex-start;
                flex-wrap: wrap;
                box-sizing: border-box;
                line-height: normal;
                line-height: 0.8;
                background-color: #f8f9fa;
                // padding-left: 16px;
                // padding-right: 16px;
                padding-top: 7px;

                @media (max-width: 991px) {
                    padding: 8px;
                    min-width: 80px;
                }

                p {
                    margin: 0;
                }

                select {
                    margin-top: 8px;
                    margin-bottom: 4px;
                    width: 100%;

                    @media (max-width: 991px) {
                        margin-bottom: 8px;
                    }
                }

                &.sortable {
                    cursor: pointer;

                    i {
                        opacity: 0;

                        &.activeSort {
                            opacity: 1;
                        }
                    }

                    &:hover {
                        i {
                            opacity: 1;
                        }
                    }
                }

                @media (max-width: 991px) {
                    flex: 1 0 180px;
                    height: 56px;
                }
            }
        }
    }

    tbody {
        display: flex;
        flex-direction: column;

        @media (max-width: 991px) {
            flex-wrap: nowrap;
            flex-direction: initial;
            width: auto;
            position: absolute;
            left: 0;
            padding-left: 110px;
            // top: 15px;
        }

        tr {
            display: flex;
            align-items: stretch;
            align-content: stretch;
            width: 100%;
            box-sizing: border-box;
            font-size: 13px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.87);
            background-color: rgb(255, 255, 255);
            min-height: 48px;

            @media (max-width: 991px) {
                // flex: 1 0 180px;

                max-width: 180px;
                flex-wrap: wrap;
            }

            td {
                flex: 1 0 0px;
                max-width: 100%;
                min-width: 100px;
                position: relative;
                display: flex;
                align-items: center;
                box-sizing: border-box;
                line-height: normal;
                // padding-left: 16px;
                // padding-right: 16px;
                word-break: break-word;

                .fa {
                    font-size: 12px !important;
                    display: inline-block;
                    margin-right: 3px;
                }

                @media (max-width: 991px) {
                    flex: 1 0 180px;
                    min-width: 180px;
                    height: 56px;

                    .btn {
                        text-wrap: nowrap;
                    }
                }
            }
        }
    }

    // tbody{
    //   height:200px;
    //   overflow-y:auto;
    //   width: 100%;
    //   }
    // thead,tbody,tr,td,th{
    //   display:block;
    // }
    // tbody{
    //   td{
    //     float:left;
    //   }
    // }
    // thead {
    //   tr{
    //     th{
    //       float:left;
    //      background-color: #f39c12;
    //      border-color:#e67e22;
    //     }
    //   }
    // }
}

.statistic-text-area {
    // background-color:#e5e7e8;
    padding: 12px;

    ul {
        // max-width: 400px;
        font-size: 18px;
        margin-bottom: 0;
        font-weight: bold;

        @media (max-width: 1360px) {
            font-size: 14px;
            font-weight: normal;
        }

        li {
            display: flex;
            justify-content: space-between;
            margin-bottom: 4px;

            .label {}

            .value {
                font-weight: bold;
            }
        }
    }
}

.dashboard-chart {
    margin-top: 24px;
    // width: 300px;
    height: 300px;
    max-width: 300px;

    @media (max-width: 1200px) {
        // width: 260px;
        height: 260px;
    }
}

.flex-table-responsive {

    // max-width: 400px;
    .flex-table {
        width: 100%;

        .tr {
            display: flex;
            justify-content: stretch;

            .th {
                font-weight: bold;
            }

            .th,
            .td {
                // flex: 1 1 0;
                width: 33.3333%;
                padding: 0.75rem;
                border-top: 1px solid #dee2e6;

                @media (max-width: 991px) {
                    font-size: 12px;
                }

                .btn {}
            }
        }

        .thead {
            width: 100%;
        }

        .tbody {
            .tr {
                display: flex;
                justify-content: stretch;

                &:nth-of-type(odd) {
                    background-color: rgba(0, 0, 0, 0.05);
                }

                .th {
                    .text-nowrap {}
                }

                .td {
                    text-wrap: wrap;

                    .q-badge {}
                }
            }
        }

        @media (max-width: 991px) {

            .thead,
            .tbody {
                .tr {

                    .th:first-child,
                    .td:first-child {
                        width: 57%;
                    }
                }
            }
        }
    }
}

.modal-btn-container {
    max-width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .btn {
        width: 45%;
    }
}

.my-transcations {
    .card-header {
        @media (max-width: 991px) {
            padding-right: 0;
            padding-left: 0;
        }
    }
}

// g.recharts-layer.recharts-cartesian-axis.recharts-yAxis.yAxis,
// g.recharts-layer.recharts-cartesian-axis.recharts-xAxis.xAxis
// {
//   .recharts-cartesian-axis-ticks {
//     &:first-child{
//       display: none;
//     }
//     .recharts-cartesian-axis-tick-line {
//       color: red;
//       &:first-child{
//         display: none;
//       }
//     }
//     .recharts-cartesian-axis-tick:last-child {
//       .recharts-cartesian-axis-tick-line {

//       }
//     }
//   }
// }

// asdfasdfasdf

.styled-select select {
    background: transparent;
    width: 107%;
    padding: 5px 5px 5px 15px;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    height: 41px;
    color: #868e95;
    margin: 0;
    font-weight: 400;
    -moz-appearance: window;
    -webkit-appearance: none;
    cursor: pointer;
    outline: none;
}

.styled-select select:focus {
    color: #555;
}

.styled-select {
    width: 100%;
    overflow: hidden;
    height: 44px;
    background: #fff url("data:image/svg+xml,%3c?xml%20version='1.0'%20encoding='utf-8'?%3e%3c!--%20Generator:%20Adobe%20Illustrator%2015.0.0,%20SVG%20Export%20Plug-In%20.%20SVG%20Version:%206.00%20Build%200)%20--%3e%3c!DOCTYPE%20svg%20PUBLIC%20'-//W3C//DTD%20SVG%201.1//EN'%20'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3e%3csvg%20version='1.1'%20id='Layer_1'%20xmlns='http://www.w3.org/2000/svg'%20xmlns:xlink='http://www.w3.org/1999/xlink'%20x='0px'%20y='0px'%20width='32px'%20height='32px'%20viewBox='0%200%2032%2032'%20enable-background='new%200%200%2032%2032'%20xml:space='preserve'%3e%3cpath%20fill='%23D6D6D6'%20d='M12.742,19.966l-4.184-4.184c-0.385-0.383-0.385-1.011,0-1.394l0.35-0.35c0.383-0.383,1.011-0.383,1.394,0%20l3.192,3.192l3.193-3.192c0.383-0.383,1.011-0.383,1.394,0l0.351,0.35c0.383,0.383,0.383,1.01,0,1.394l-4.185,4.184%20c-0.206,0.206-0.481,0.298-0.752,0.282C13.225,20.264,12.949,20.172,12.742,19.966z'/%3e%3c/svg%3e") no-repeat right center;
    border: 1px solid #ced4da;
    -webkit-border-radius: 0.25rem;
    -moz-border-radius: 0.25rem;
    -ms-border-radius: 0.25rem;
    border-radius: 0.25rem;
}

.styled-select select::-ms-expand {
    display: none;
}

.styled-select select:focus {
    outline: none;
    box-shadow: none;
}

// asdfasdfasdf

.trans-box {
    form {
        margin-bottom: 16px !important;

        .label-container {
            padding-top: 6px;
        }

        &>*:not(.modal-overlay) {
            display: flex;
            width: 100%;
            align-items: flex-start;

            &>* {
                width: 100%;
                margin: 0;
                align-items: center;

                // &.form-group.input-group.flex-nowrap{
                //   align-self: flex-start;
                // }
                p.form-control {
                    line-height: 32px;
                }

                @media (max-width: 991px) {
                    margin-bottom: 1rem;
                }

                label {
                    margin-bottom: 0;
                }

                .input-group-text {
                    padding: 10px 12px;

                    .fa {
                        line-height: 13px !important;
                    }
                }
            }
        }
    }

    .styled-select {
        select {
            position: absolute;
            left: 0;
            width: 100% !important;

            line-height: 1;
        }

        label {
            margin-bottom: 0;
        }
    }
}

.login-from-q form .form-group i {
    top: 31px !important;
    // &.pwd {
    //     right: 8px;
    //     left: auto;
    //     font-size: 24px !important;
    //     cursor: pointer;
    // }
}

.authentication-form {
    .pwd {
        position: absolute;
        right: 10px;
        left: auto !important;
        font-size: 24px !important;
        cursor: pointer;
        top: 6px;
    }
}

.q-menu-1 {
    margin-top: 15px !important;

    @media (max-width: 991px) {
        // margin-top: 18px;
    }
}

.quan-toggle {}

body.sticky-footer .content-wrapper {
    min-height: calc(100vh - 162px - 162px) !important;
}

a.btn_1,
.btn_1 {
    border-radius: 25px;
}

.buy-currency-wrapper {
    .row {
        // margin-bottom: 40px !important;
        // .form-group {
        //   position: relative;
        //   .form-error-message {
        //     position: absolute;
        //   }
        // }
    }

    .input-group-text {
        padding: 0.275rem 0.75rem !important;
    }
}

.admin-dashboard-table-1 {

    th,
    td {
        font-size: 18px;

        @media (max-width: 1199px) {
            font-size: 16px;
        }

        @media (max-width: 767px) {
            font-size: 14px;
        }
    }

    table {
        tbody {
            tr {
                td {
                    width: 60%;

                    @media (max-width: 1199px) {
                        width: 40%;
                    }
                }
            }
        }
    }
}

.search-by-wrap {
    display: inline-flex;
    // max-width: 400px;
    align-items: center;
    position: relative;
    z-index: 0;

    &.half {
        // max-width: 200px;
    }

    select {
        // height: auto !important;
        // min-width: calc(55% - 8px);
        // padding: 0;
        margin-right: 8px;
    }

    .input-group {
        // min-width: 55%;
    }
}

.vote-questions-list {
    h3 {
        // border-bottom: 2px solid #787878;
    }

    .single-question {
        margin-bottom: 32px;
        border-bottom: 2px dotted #787878;
        padding-top: 32px;
        padding-bottom: 32px;

        h6 {}

        .form-group {
            .form-control {}
        }

        .question-options {
            h6 {}

            .input-group {
                .input-group-text {
                    .input-group-text {
                        label {
                            line-height: 1;
                            margin-bottom: 0;
                            margin-right: 4px;
                            display: inline-block;
                        }

                        #A2 {}
                    }
                }

                .form-control {}
            }
        }

        .d-flex {
            .btn {
                span {
                    .fa {}
                }
            }
        }
    }
}

.question-single-view {
    border-bottom: 1px solid #dee2e6;
}

.idea-block-list {
    .idea-block {
        margin-bottom: 20px;

        h4 {}

        div {}
    }
}